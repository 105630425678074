<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-card uk-card-default ">
            <div class="uk-modal-body">
                    <div class="uk-grid">
                        <div class="uk-width-1-2">
                            <div class="uk-margin">
                                <label class="uk-form-label">Nama Lengkap <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('fullname')}"
                                    name="fullname"
                                    type="text"
                                    v-model="form.fullname"
                                    v-validate="'required'"
                                    placeholder="Jonie">
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('fullname')">{{ errors.first('fullname') }}</span>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Email <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('email')}"
                                    name="email"
                                    type="email"
                                    v-validate="'required'"
                                    v-model="form.email"
                                    placeholder="jonie@robin.id">
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('email')">{{ errors.first('email') }}</span>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Telepon</label>
                                <input
                                    class="uk-input"
                                    type="text"
                                    v-model="form.phone"
                                    placeholder="0891212121212">
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Nama Posisi <b class="uk-text-danger">*</b></label>
                                <input
                                    class="uk-input"
                                    :class="{'uk-form-danger': errors.has('position')}"
                                    name="position"
                                    type="text"
                                    v-model="form.position"
                                    v-validate="'required'"
                                    placeholder="Senior Developer">
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('position')">{{ errors.first('position') }}</span>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Test Buta Warna</label>
                                    <select
                                        class="uk-select"
                                        id="form-horizontal-select"
                                        v-model="form.test_buta_warna"
                                    >
                                        <option v-for="(data, i) in cv_datas.test" :key="i" :value="data.value">{{data.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Test Kemampuan Dasar</label>
                                    <select
                                        class="uk-select"
                                        id="form-horizontal-select"
                                        v-model="form.test_kemampuan"
                                    >
                                        <option v-for="(data, i) in cv_datas.test" :key="i" :value="data.value">{{data.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-label">Upload CV <b class="uk-text-danger">*</b></div>
                                <img :src="form.cv_url ? form.cv_url.file_url : ''" alt="" width="100%" height="100%">
                                <input
                                    @change="uploadCV"
                                    name="cv_file"
                                    ref="cv_file"
                                    type="file"
                                    :class="{'button-upload': true, 'uk-form-danger': errors.has('cv_file')}"
                                    v-validate="'ext:jpeg,jpg,png,pdf,doc,docx'">
                                <span v-show="errors.has('cv_file')" class="uk-text-small uk-text-danger">{{ errors.first('cv_file') }}</span>
                            </div>
                        </div>
                        <div class="uk-width-1-2">
                            <div class="uk-grid">
                                <div class="uk-width-1-2">
                                    <div class="uk-margin">
                                        <div class="uk-form-controls">
                                            <label class="uk-form-label">Kecamatan <b class="uk-text-danger">*</b></label>
                                            <v-select
                                                :options="Subdistrict"
                                                :filterable="false"
                                                :class="{'uk-form-danger': errors.has('sub_district')}"
                                                v-model="form.sub_district"
                                                name="sub_district"
                                                placeholder="Cari.."
                                                @search="query => search = query"
                                            >
                                            </v-select>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('sub_district')">{{ errors.first('sub_district') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-2">
                                    <div class="uk-margin">
                                        <div class="uk-form-controls">
                                            <label class="uk-form-label">Kota <b class="uk-text-danger">*</b></label>
                                            <v-select
                                                :options="City"
                                                :filterable="false"
                                                :class="{'uk-form-danger': errors.has('city')}"
                                                v-model="form.city"
                                                name="city"
                                                placeholder="Cari.."
                                                @search="query => search = query"
                                            >
                                            </v-select>
                                            <span class="uk-text-small uk-text-danger" v-show="errors.has('city')">{{ errors.first('city') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Pendidikan <b class="uk-text-danger">*</b></label>
                                    <select
                                        class="uk-select"
                                        :class="{'uk-form-danger': errors.has('pendidikan')}"
                                        name="pendidikan"
                                        v-validate="'required'"
                                        id="form-horizontal-select"
                                        v-model="form.education"
                                    >
                                        <option :value="''" disabled>Pilih Pendidikan</option>
                                        <option v-for="(data, i) in cv_datas.education" :key="i" :value="data.value">{{data.name}}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('pendidikan')">{{ errors.first('pendidikan') }}</span>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Industri</label>
                                <input v-if="cv_classification.type === 'golife'"
                                    disabled
                                    class="uk-input"
                                    type="text"
                                    v-model="form.industry"
                                    placeholder="Komunikasi">
                                <input v-else
                                    class="uk-input"
                                    type="text"
                                    v-model="form.industry"
                                    placeholder="Komunikasi">
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Desksipsi <b class="uk-text-danger">*</b></label>
                                    <textarea
                                        class="uk-textarea"
                                        :class="{'uk-form-danger': errors.has('description')}"
                                        name="description"
                                        type="text"
                                        rows="5"
                                        v-model="form.description"
                                        v-validate="'required'"
                                        :maxlength="250"
                                        placeholder="Keahlian dan Pengalaman"/>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
                                </div>
                            </div>
                            <div class="uk-margin">
                                <label class="uk-form-label">Komentar</label>
                                <textarea
                                    class="uk-textarea"
                                    type="text"
                                    rows="5"
                                    v-model="form.comment"
                                    placeholder="Sepertinya kamu">
                                </textarea>
                            </div>
                            <div class="uk-margin">
                                <div class="uk-form-controls">
                                    <label class="uk-form-label">Status CV</label>
                                    <select
                                        class="uk-select"
                                        name="status_cv"
                                        id="form-horizontal-select"
                                        v-model="form.status"
                                    >
                                        <option v-for="(data, i) in cv_datas.status" :key="i" :value="data.value">{{data.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-margin-right" type="button" @click="toBack" :disabled="isLoading">Back</button>
                <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                <button v-else class="uk-button uk-button-primary" type="button" @click="saveCVClassified">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import formatter from "@/utils/formatter";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import axios from "axios";

export default {
    data() {
        return {
            form: {
                type: 'admin',
                status: 'done',
                fullname: '',
                phone: '',
                email: '',
                description: '',
                education: '',
                city: '',
                sub_district: '',
                industry: '',
                position: '',
                comment: '',
                test_kemampuan: '',
                test_buta_warna: '',
                cv_url: {}
            },
            isLoading: false,
            city: [],
            sub_district: [],
            limit: 50,
            search: ''
        };
    },
    components: {
        vSelect
    },
    computed : {
        ...mapGetters({
            cv_classification: 'cv_classification/cv_classification',
            cv_datas: 'cv_classification/cv_datas',
        }),
        filteredCity() {
            return this.city.filter(city => city.toLowerCase().includes(this.search));
        },
        filteredSubdistrict() {
            return this.sub_district.filter(sub => sub.toLowerCase().includes(this.search));
        },
        City() {
            return this.filteredCity.slice(0, this.limit);
        },
        Subdistrict() {
            return this.filteredSubdistrict.slice(0, this.limit);
        }
    },
    async mounted() {
        await Promise.all([
            this.getCVDetail(this.$route.params.cv_id),
            this.getCVData()
        ]);
        this.populateData();
        this.dataCity();
        this.dataSubstrict();
    },
    methods: {
        ...mapActions({
            getCVDetail: 'cv_classification/getCVDetail',
            getCVData: 'cv_classification/getCVData',
            updateCV: 'cv_classification/updateCV'
        }),
        populateData() {
            this.form = {
                fullname: this.cv_classification.fullname,
                phone: this.cv_classification.phone,
                email: this.cv_classification.email,
                position: this.cv_classification.position,
                industry: this.cv_classification.industry,
                type: this.cv_classification.type,
                status: this.cv_classification.status,
                city: this.cv_classification.city,
                sub_district: this.cv_classification.sub_district,
                description: this.cv_classification.description,
                education: this.cv_classification.education,
                comment: this.cv_classification.comment,
                test_buta_warna: this.cv_classification.test_buta_warna,
                test_kemampuan: this.cv_classification.test_kemampuan,
                cv_url: this.cv_classification.cv_url
            };
        },
        getDate(data) {
            return formatter.dateComplete(data);
        },
        toBack() {
            window.location.href = '/admin/cv-classification';
        },
        uploadCV(e) {
            const file = e.target.files[0];
            this.form.cv_url.file_url = URL.createObjectURL(file);
        },
        saveCVClassified() {
            this.$validator.validateAll().then(async (success) => {
                if (success) {
                    if (this.$validator.errors.any()) return;

                    this.isLoading = true;
                    const formData = new FormData();
                    this.$refs.cv_file.files[0] && formData.append("file_cv", this.$refs.cv_file.files[0]);
                    formData.append("fullname", this.form.fullname);
                    this.form.phone && formData.append("phone", this.form.phone);
                    formData.append("email", this.form.email);
                    formData.append("sub_district", this.form.sub_district);
                    formData.append("description", this.form.description);
                    this.form.industry && formData.append("industry", this.form.industry);
                    formData.append("position", this.form.position);
                    formData.append("city", this.form.city);
                    formData.append("type", this.form.type);
                    formData.append("status", this.form.status);
                    formData.append("education", this.form.education);
                    this.form.comment && formData.append("comment", this.form.comment);
                    this.form.test_buta_warna && formData.append("test_buta_warna", this.form.test_buta_warna);
                    this.form.test_kemampuan && formData.append("test_kemampuan", this.form.test_kemampuan);

                    const response = await this.updateCV({cvId: this.$route.params.cv_id, data: formData});

                    if (response) {
                        window.location.href = "/admin/cv-classification";
                    } else {
                        this.isLoading = false;
                    }
                }
            });
        },
        dataCity() {
            axios.get('/static/kota-kabupaten.json').then(res => {
                this.city = res.data;
            }).catch((err) => {
                console.error(err);
            });
        },
        dataSubstrict() {
            axios.get('/static/kecamatan.json').then((res) => {
                this.sub_district = res.data;
            }).catch((err) => {
                console.error(err);
            });
        }
    },
};
</script>
